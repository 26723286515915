import {
  Autocomplete,
  Avatar,
  Box,
  Breadcrumbs,
  CircularProgress,
  Collapse,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import LayoutComponent from '../../../components/LayoutComponent';
import TwoRowLayout from '../../../core-components/theme/layouts/TwoLayout/TwoLayoutComponent';
import { Neutrals, Primary } from '../../../core-components/theme/color-palette/colors';
import CustomUserTreeItem from './CustomUserTree';
import CustomTreeComponent from '../../../core-components/theme/components/Tree/CustomTree';
import { getWorkspaceItemlabel } from '../../workspace-management/helpers/customtreehelpers';
import { useEffect, useRef, useState } from 'react';
import useIsMobile from '../../../utilities/customHooks/useIsMobile';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import CustomIconButton from '../../../core-components/theme/components/Button/Iconbutton';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import useIsTablet from '../../../utilities/customHooks/useIsTablet';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {
  generatePassword,
  resizeImage,
  validateEmail,
  validateName,
  validatePassword,
  validatePhone,
} from './userHelperFunction';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
// import { updateProfilePicture } from '../../../services/profile';
import Button from '../../../core-components/theme/components/Button/Button';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  buildWorkspacePermissionTree,
  getAdministrativeRoles,
  getUserDetails,
  saveUser,
} from '../../../services/newUsers';
import { useWorkspaceAssetRoleStore } from '../store/workspaceAssetRoleStore';
import { getUserGroupsForUser } from '../../../services/role';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
// import { handleRoleAddForEditPage } from './helpers/helper';
import { AdministrativeRole, FormData, UserGroup } from './interface/user.interface';

const styles = {
  refreshIconStyle: {
    borderLeft: '1px solid #0000003B',
    borderRadius: '0px',
    padding: '1rem',
    background: '#ffffff',
    marginLeft: '1rem',
  },
  uploadIconStyle: {
    border: '1px solid #1836501A',
    borderRadius: '4px',
    padding: '1rem',
    marginBottom: '1rem',
    height: '57px',
  },
  profileIconStyle: {
    width: '56px',
    height: '56px',
    fontSize: '1rem',
    background: Primary[900],
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 400,
    marginBottom: '1rem',
    cursor: 'pointer',
  },
  chipStyle: {
    background: '#ffffff',
    border: '1px solid #BDBDBD',
    borderRadius: '100px',
  },
};
const initialData = {
  name: '',
  password: '',
  email: '',
  userGroups: [],
  administrativeRoles: [],
  phone: '',
  profilePic: '',
};
const UserEditPage = () => {
  const isMobile = useIsMobile({ breakpointValue: 'sm' });
  const { id } = useParams();
  const isTablet = useIsTablet();
  const [open, setOpen] = useState(true);
  const [showPassword, setShowPassword] = useState(true);
  const [roles, setRoles] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  // const [workspacePermissionTree, setWorkspacePermissionTree] = useState<any>([]);
  const [formData, setFormData] = useState<FormData>({
    //@ts-ignore
    updatedUserId: id,
    ...initialData,
  });

  const [nameError, setNameError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [, setLoadImage] = useState(false);
  const [openDialogueBox, setOpenDialogueBox] = useState(false);
  const mode = 'edit';
  const [firstRender, setFirstRender] = useState(true);
  const [workspacePermissionTreePayload, setWorkspacePermissionTreePayload] = useState({
    userGroupIds: [],
    workspaceAssetRoles: [],
    childUserId: id,
    mode: mode,
  });
  const saveDisabled =
    formData?.name?.length === 0 ||
    formData?.email?.length === 0 ||
    formData?.phone?.length === 0 ||
    nameError?.length !== 0 ||
    phoneError?.length !== 0 ||
    emailError?.length !== 0;
  const resetWorkspaceAssetRoles = useWorkspaceAssetRoleStore(
    state => state.resetWorkspaceAssetRoles
  );
  const { workspaceAssetRoles } = useWorkspaceAssetRoleStore(); // Get the roles from Zustand store
  const queryClient = useQueryClient();
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/userList">
      <Typography key="3" color="#666666">
        User List
      </Typography>
    </Link>,
    <Typography key="3" color="#666666">
      Edit User
    </Typography>,
  ];

  useEffect(() => {
    setWorkspacePermissionTreePayload((prev: any) => ({
      ...prev,
      workspaceAssetRoles: workspaceAssetRoles, // Update roles here
    }));
  }, [workspaceAssetRoles]);

  function handleOpen() {
    setOpenDialogueBox(true);
  }

  function handleClose() {
    setOpenDialogueBox(false);
  }

  const handleToggle = () => {
    setOpen(!open);
  };

  function handleFormData(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    const tempFormData = { ...formData, [name]: value };
    setFormData(tempFormData);
  }
  const { data: userDetails, status: userDetailsStatus } = useQuery({
    queryKey: ['userDetails', id],
    queryFn: () => getUserDetails(id as string),
    enabled: !!id,
    refetchOnWindowFocus: false,
  });
  // useQuery for fetching workspace permission tree
  const {
    data: userGroupsResponse,
    status: userGroupsStatus,
    // refetch: refetchUserGroups,
  } = useQuery({
    queryKey: ['userGroupsForUser'],
    enabled: userDetailsStatus === 'success',
    queryFn: () => getUserGroupsForUser('edit', id),
    refetchOnWindowFocus: false,
  });
  const {
    data: administrativeRole,
    status: administrativeRoleStatus,
    // refetch: refetchAdministrative,
  } = useQuery({
    queryKey: ['administrativeRole'],
    queryFn: () =>
      getAdministrativeRoles({
        mode: mode,
        userGroupIds: formData.userGroups.map(userGroup => userGroup._id),
        updatedUserId: id,
      }),
    enabled: userGroupsStatus === 'success',
    refetchOnWindowFocus: false,
  });
  const {
    data: workspacePermissionTreeResponse,
    isFetching,
    error,
  } = useQuery({
    queryKey: ['workspacePermissionTree', workspacePermissionTreePayload],
    queryFn: () => {
      const payloadCopy = { ...workspacePermissionTreePayload };
      if (
        (!firstRender && workspaceAssetRoles?.length) ||
        (workspacePermissionTreeResponse?.length && payloadCopy.hasOwnProperty('mode'))
      ) {
        //@ts-ignore
        delete payloadCopy.mode;
      }
      setFirstRender(false);
      return buildWorkspacePermissionTree(payloadCopy);
    },
    enabled: administrativeRoleStatus === 'success' && formData.userGroups.length > 0, // Ensure administrativeRoles and userGroups are ready
    refetchOnWindowFocus: false,
  });
  const navigate = useNavigate();
  const handleCancel = () => {
    navigate('/userList');
  };
  const handleUserSave = () => {
    if (saveDisabled) {
      return;
    }
    formData.name = formData.name.trim();
    formData.phone = formData.phone.trim();
    formData.email = formData.email.trim();
    if (!validateEmail(formData, setEmailError)) {
      return;
    }
    if (!validateName(formData, setNameError)) {
      return;
    }
    if (!validatePhone(formData, setPhoneError)) {
      return;
    }
    //@ts-ignore
    formData.userGroups = formData.userGroups.map(userGroup => userGroup._id);
    //@ts-ignore
    formData.administrativeRoles = formData.administrativeRoles.map(
      administrativeRole => administrativeRole._id
    );
    formData.workspaceAssetRoles = workspaceAssetRoles;
    mutation.mutate(formData);
  };

  const mutation = useMutation({
    mutationFn: (formData: FormData) => saveUser(formData),
    onSuccess: () => {
      // Check if the response indicates success or failure
      toast.success(`User created`);
      navigate('/userList'); // Redirect to another page
      queryClient.invalidateQueries({ queryKey: ['workspacePermissionTree'], exact: false });
      resetWorkspaceAssetRoles();
    },
    onError: error => {
      toast.error(error?.message || `Problem in saving role`);
      console.error('Error saving role:', error);
    },
  });
  // Update state based on query response
  // * This useEffect is for User Details
  useEffect(() => {
    if (userDetailsStatus === 'success') {
      setFormData(prevState => ({
        ...prevState,
        name: userDetails.name,
        email: userDetails.email,
        phone: userDetails.number,
      }));
    } else if (userDetailsStatus === 'error') {
      console.error('Error loading user details:', error);
    }
  }, [userDetailsStatus, userDetails]);

  // * This useEffect is for Administrative Role
  useEffect(() => {
    if (administrativeRoleStatus === 'success') {
      setRoles(administrativeRole);
      setFormData(prevState => ({
        ...prevState,
        administrativeRoles: administrativeRole.filter(
          (ar: { selected: boolean }) => ar.selected === true
        ),
      }));
    } else if (administrativeRoleStatus === 'error') {
      console.error('Error loading administrative roles:', error);
    }
  }, [administrativeRoleStatus, administrativeRole]);

  // * This useEffect is for User Groups
  useEffect(() => {
    if (userGroupsStatus === 'success') {
      const selectedUserGroups = userGroupsResponse.filter(
        (u: { selected: boolean }) => u.selected === true
      );
      setUserGroups(userGroupsResponse);
      setFormData(prevState => ({
        ...prevState,
        userGroups: selectedUserGroups,
      }));
      //@ts-ignore
      setWorkspacePermissionTreePayload(prevState => ({
        ...prevState,
        userGroupIds: selectedUserGroups.map((group: { _id: any }) => group._id),
      }));
    }
  }, [JSON.stringify(userGroupsResponse), userGroupsStatus]);

  function handleUserGroupChange(event: any, value: any[]) {
    //@ts-ignore
    setFormData(prevState => ({
      ...prevState,
      userGroups: [...value],
    }));
    //@ts-ignore
    setWorkspacePermissionTreePayload(prevState => ({
      ...prevState,
      userGroupIds: [...value.map((group: { _id: any }) => group._id)],
    }));
    setTimeout(() => {
      queryClient.invalidateQueries({ queryKey: ['workspacePermissionTree'], exact: false });
    }, 0);
  }

  function handleAdministrativeChange(event: any, value: any) {
    setFormData(prevState => ({
      ...prevState,
      administrativeRoles: value,
    }));
  }

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const resizedImage = await resizeImage(file);
      if (resizedImage) {
        /*
            Will pass blob when save API is created
            setFormData({
              ...formData,
              profilePic: resizedImage 
            })
          */
        const reader = new FileReader();
        reader.onloadend = () => {
          const result = reader.result;
          if (typeof result === 'string') {
            setFormData({
              ...formData,
              profilePic: result, //passing image url for now
            });
            event.target.value = '';
          }
        };
        reader.readAsDataURL(file);
        // uploadImage(resizedImage); //need to add this line while saving formData do this when save API is created
      }
    }
  };

  // const uploadImage = async (resizedImage: Blob) => {
  //   const formData = new FormData();
  //   formData.append('upload', resizedImage, 'profilePic');
  //   try {
  //     await updateProfilePicture(formData)
  //       .then((data: any) => {
  //         if (data.status === 200) {
  //           toast.success('Profile picture updated successfully');
  //         }
  //       })
  //       .catch((err: any) => {
  //         console.log(err);
  //       });
  //   } catch (error) {
  //     console.error('There was an error uploading the image:', error);
  //   }
  // };

  const handleDelete = () => {
    setFormData({
      ...formData,
      profilePic: '',
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setLoadImage(true);
      }
    });

    const avatar = document.getElementById('lazy-avatar');
    if (avatar) {
      observer.observe(avatar);
    }

    return () => {
      if (avatar) {
        observer.unobserve(avatar);
      }
    };
  }, []);

  return (
    <LayoutComponent factoryResetContainer={true}>
      <TwoRowLayout
        style={{ pl: 4, pr: 4 }}
        child1={
          <Stack direction="column" width="100%">
            <Breadcrumbs
              separator={<ArrowForwardIcon fontSize="small" sx={{ color: '#666666' }} />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
            <Box
              sx={{
                mt: 2,
                mb: 2,
                backgroundColor: `${Primary[50]}`,
                borderRadius: 3,
                padding: isMobile ? '16px 24px' : '24px',
                border: '1px solid #bdbdbd',
              }}
            >
              <Grid container direction="column" borderRadius="12px">
                <Grid
                  container
                  onClick={handleToggle}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {isMobile && (
                    <Typography
                      variant="body"
                      letterSpacing="0.15px"
                      color="#666"
                      lineHeight="24px"
                    >
                      User Creation Details
                    </Typography>
                  )}
                  {isMobile && (
                    <IconButton onClick={handleToggle}>
                      {open ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  )}
                </Grid>
                {isMobile && open && (
                  <Divider variant="fullWidth" sx={{ mt: 1, mb: '24px', color: '#bdbdbd' }} />
                )}
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Grid
                    container
                    display="flex"
                    direction={isMobile ? 'column' : 'row'}
                    justifyContent="space-between"
                    alignItems="flex-start"
                    flexWrap="nowrap"
                    gap={4}
                    mb={-2}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={isMobile ? 5 : 7}
                      width={isMobile ? '100%' : undefined}
                      display="flex"
                      gap={4}
                    >
                      <Avatar
                        id="lazy-avatar"
                        style={styles.profileIconStyle}
                        src={formData.profilePic}
                        onClick={handleOpen}
                      >
                        {formData.name
                          .split(' ')
                          .map(name => name[0])
                          .join('')}
                      </Avatar>
                      <CustomIconButton
                        icon={FileUploadIcon}
                        onClick={handleButtonClick}
                        sx={styles.uploadIconStyle}
                      />
                      <input
                        ref={fileInputRef}
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                      />
                      <Dialog
                        open={openDialogueBox}
                        onClose={handleClose}
                        sx={{
                          '.MuiDialog-paper': {
                            borderRadius: '24px',
                          },
                        }}
                      >
                        <Stack direction="column">
                          <Box display="flex" justifyContent="flex-end" mr={2} mt={2}>
                            <IconButton onClick={handleClose}>
                              <CloseIcon fontSize="small" sx={{ color: '#000000' }} />
                            </IconButton>
                          </Box>
                          <Box pl={8} pr={8} pb={3} position="relative">
                            <Avatar
                              id="lazy-avatar"
                              style={{
                                ...styles.profileIconStyle,
                                height: '200px',
                                width: '200px',
                                fontSize: '3rem',
                              }}
                              src={formData.profilePic}
                              onClick={handleOpen}
                            >
                              {formData.name
                                .split(' ')
                                .map(name => name[0])
                                .join('')}
                            </Avatar>
                            <IconButton
                              onClick={handleDelete}
                              sx={{
                                color: 'white',
                                position: 'absolute',
                                bottom: 0,
                                left: '50%',
                                transform: 'translateX(-50%)',
                                '&:hover': {
                                  backgroundColor:
                                    formData.profilePic !== '' ? '#ffffff' : '#000000',
                                },
                                marginBottom: '2rem',
                              }}
                            >
                              <DeleteIcon
                                sx={{ color: formData.profilePic !== '' ? '#000000' : '#ffffff' }}
                                fontSize="small"
                              />
                            </IconButton>
                          </Box>
                        </Stack>
                      </Dialog>
                      <TextField
                        label="Full Name"
                        placeholder="Full Name"
                        required
                        value={formData.name}
                        name="name"
                        onChange={handleFormData}
                        variant="outlined"
                        fullWidth
                        onBlur={() => {
                          validateName(formData, setNameError);
                        }}
                        error={Boolean(nameError)}
                        helperText={nameError}
                        inputProps={{
                          onInput: (e: React.ChangeEvent<HTMLInputElement>) => {
                            e.target.value = e.target.value
                              .replace(/[^a-zA-Z0-9\s]/g, '')
                              .replace(/^\s/, '')
                              .replace(/\s{2,}/g, ' ');
                          },
                        }}
                        sx={{
                          '.MuiFormHelperText-root': {
                            margin: '0px',
                          },
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      mt={isMobile ? -4 : 0}
                      xs={12}
                      sm={5}
                      width={isMobile ? '100%' : undefined}
                    >
                      <TextField
                        label="Email"
                        name="email"
                        required
                        placeholder="email"
                        value={formData.email}
                        onChange={handleFormData}
                        variant="outlined"
                        fullWidth
                        sx={{
                          paddingBottom: isMobile ? '1rem' : '0.5rem',
                          '.MuiFormHelperText-root': {
                            margin: '0px',
                          },
                        }}
                        onBlur={() => {
                          validateEmail(formData, setEmailError);
                        }}
                        error={Boolean(emailError)}
                        helperText={emailError}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={5}
                      width={isMobile ? '100%' : undefined}
                      sx={{
                        '.MuiOutlinedInput-root': {
                          padding: '0px',
                        },
                      }}
                    >
                      <TextField
                        label="Password"
                        name="password"
                        autoComplete="new-password"
                        type={showPassword ? 'password' : 'text'}
                        placeholder="Password"
                        value={formData.password}
                        onChange={handleFormData}
                        variant="outlined"
                        fullWidth
                        disabled={!formData.password}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                              <IconButton
                                sx={styles.refreshIconStyle}
                                onClick={() =>
                                  setFormData({ ...formData, password: generatePassword() })
                                }
                              >
                                <AutorenewIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          marginTop: isMobile ? '-1rem' : '',
                          '.MuiOutlinedInput-root': {
                            paddingRight: '0px !important',
                            '.MuiFormHelperText-root': {
                              margin: '0px',
                            },
                          },
                        }}
                        onBlur={() => {
                          validatePassword(formData, setPasswordError);
                        }}
                        error={Boolean(passwordError)}
                        helperText={passwordError}
                        inputProps={{
                          onInput: (e: React.ChangeEvent<HTMLInputElement>) => {
                            e.target.value = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction={isMobile ? 'column' : 'row'}
                    justifyContent="space-around"
                    alignItems="flex-start"
                    gap={4}
                    mt={isMobile ? 6 : 2}
                    flexWrap="nowrap"
                  >
                    <Grid item xs={12} sm={4} width={isMobile ? '100%' : undefined}>
                      <TextField
                        label="Phone Number"
                        placeholder="Phone Number"
                        value={formData.phone}
                        name="phone"
                        onChange={handleFormData}
                        variant="outlined"
                        fullWidth
                        onBlur={() => {
                          validatePhone(formData, setPhoneError);
                        }}
                        error={Boolean(phoneError)}
                        helperText={phoneError}
                        inputProps={{
                          // minLength: 10,
                          onInput: (e: React.ChangeEvent<HTMLInputElement>) => {
                            // Replace anything not matching the allowed pattern: alphanumeric, +, -, with at least 10 digits
                            const input = e.target.value;
                            const regex = /^(?=.*\d{10,})[a-zA-Z0-9+\-]+$/;

                            if (!regex.test(input)) {
                              // Allow only characters that match the criteria, stripping out unwanted characters
                              e.target.value = input.replace(/[^a-zA-Z0-9+\-]/g, '');
                            }
                          },
                        }}
                        sx={{
                          '.MuiFormHelperText-root': {
                            margin: '0px',
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} width={isMobile ? '100%' : undefined}>
                      <Autocomplete
                        // name="userGroup"
                        multiple
                        limitTags={1}
                        id="multiple-limit-tags"
                        options={userGroups}
                        value={formData.userGroups}
                        getOptionLabel={(option: UserGroup) => option.name}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="User Group"
                            placeholder={formData.userGroups.length > 0 ? '' : 'User Group'}
                          />
                        )}
                        sx={{
                          '.MuiChip-root': styles.chipStyle,
                          '.MuiOutlinedInput-root': {
                            padding: isTablet && formData.userGroups.length > 2 ? '7px' : '9px',
                          },
                        }}
                        onChange={handleUserGroupChange}
                        isOptionEqualToValue={(option, value) => option._id === value._id}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} width={isMobile ? '100%' : undefined}>
                      <Autocomplete
                        // name="administrativeRole"
                        multiple
                        limitTags={1}
                        id="multiple-limit-tags"
                        options={roles}
                        getOptionLabel={(option: AdministrativeRole) => option.name}
                        value={formData.administrativeRoles}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Administrative Role"
                            placeholder={
                              formData.administrativeRoles.length > 0 ? '' : 'Administrative Role'
                            }
                          />
                        )}
                        sx={{
                          '.MuiChip-root': styles.chipStyle,
                          '.MuiOutlinedInput-root': {
                            padding:
                              isTablet && formData.administrativeRoles.length > 2 ? '7px' : '9px',
                          },
                        }}
                        onChange={handleAdministrativeChange}
                        isOptionEqualToValue={(option, value) => option._id === value._id}
                      />
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
            </Box>
          </Stack>
        }
        child2={
          <Box p={0} borderRadius="8px">
            {!formData.userGroups.length ? (
              <Box
                border={`1px solid ${Primary[100]}`}
                borderRadius="8px"
                textAlign="center"
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="10vh"
              >
                Select UserGroup for permissions
              </Box>
            ) : isFetching ? (
              <Box
                border={`1px solid ${Primary[100]}`}
                borderRadius="8px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="10vh"
                textAlign="center"
              >
                <CircularProgress />
              </Box>
            ) : (
              <CustomTreeComponent
                show={true}
                heading="Workspace / Asset"
                getItemId={(item: any) => item.itemId}
                getItemlabel={getWorkspaceItemlabel}
                CustomTreeItem={CustomUserTreeItem}
                items={workspacePermissionTreeResponse || []}
              />
            )}
            <Box
              gap={6}
              pt={4}
              sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
              pb={2}
            >
              <Button
                variant="contained"
                sx={{
                  border: `1px solid ${Neutrals[500]}`,
                  borderRadius: '4px',
                  padding: isMobile ? '10px 40px' : '10px 70px',
                  backgroundColor: 'white',
                  color: `${Neutrals[500]}`,
                  flex: isMobile ? 1 : 0,
                  boxShadow: 'none',
                  ':hover': { background: 'none', boxShadow: 'none' },
                }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                disabled={saveDisabled || mutation.isPending}
                variant="contained"
                sx={{
                  padding: isMobile ? '10px 40px' : '10px 70px',
                  flex: isMobile ? 1 : 0,
                  borderRadius: '4px',
                  backgroundColor: `${Primary[500]}`,
                  boxShadow: 'none',
                  ':hover': { boxShadow: 'none' },
                }}
                onClick={handleUserSave}
              >
                Save
              </Button>
            </Box>
          </Box>
        }
      />
    </LayoutComponent>
  );
};
export default UserEditPage;
